import React, { useState } from "react";
import "../../css/Forms.css";
import { db } from './Firebase'

const Forms = () => {
  const [values, setValues] = useState({
    userName: "",
    userEmail: "",
    userPhoneno: "",
    userCollegename: "",
    userCollegeyear: "",
  });


  const [submitted, setSubmitted] = useState(false);
  const [valid, setValid] = useState(false);

  const handlenameChange = (e) => {
    setValues({
      ...values,
      userName: e.target.value,
    });
  };


  const handleemailChange = (e) => {
    setValues({
      ...values,
      userEmail: e.target.value,
    });
  };


  const handlephoneChange = (e) => {
    setValues({
      ...values,
      userPhoneno: e.target.value,
    });
  };


  const handlecollegeChange = (e) => {
    setValues({
      ...values,
      userCollegename: e.target.value,
    });
  };


  const handleyearChange = (e) => {
    setValues({
      ...values,
      userCollegeyear: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();


    db.collection('registrations').add({
      userName: values.userName,
      userEmail: values.userEmail,
      userPhoneno: values.userPhoneno,
      userCollegename: values.userCollegename,
      userCollegeyear: values.userCollegeyear
    })
    .then(() => {
        setValid(true);
        setSubmitted(true);
    })
    .catch(error => {
      alert(error.message);
    })

    setValues({
      userName: "",
      userEmail: "",
      userPhoneno: "",
      userCollegename: "",
      userCollegeyear: ""
    })
    setValid(true);
  };


  return (
    <div className="forms-body">
      <div className="containers">
        <div class="title">Registration</div>

        <form className="forms" onSubmit={handleSubmit}>
          {submitted && valid ? (
            <div className="success-message">
              Success! Thank you for registering.
            </div>
          ) : null}
          <div className="user-details">
            <div className="input-box">
              <span htmlFor="name" className="details">
                Full Name
              </span>
              <input
                id="name"
                type="text"
                name="name"
                className="form-input"
                placeholder="Enter your Name"
                value={values.userName}
                onChange={handlenameChange}
              />
              {submitted && !values.userName ? (
                <span className="alert-message">Please enter a first name</span>
              ) : null}
            </div>

            <div className="input-box">
              <span htmlFor="email" className="details">
                Email
              </span>
              <input
                id="email"
                type="email"
                name="email"
                className="form-input"
                placeholder="Enter your email"
                value={values.userEmail}
                onChange={handleemailChange}
              />
              {submitted && !values.userEmail ? (
                <span className="alert-message">Please enter an email</span>
              ) : null}
            </div>

            <div className="input-box">
              <span htmlFor="phone" className="details">
                Phone Number
              </span>
              <input
                id="phone"
                type="tel"
                name="phone"
                className="form-input"
                placeholder="Enter your number"
                value={values.userPhoneno}
                onChange={handlephoneChange}
              />
              {submitted && !values.userPhoneno ? (
                <span className="alert-message">Please enter a phone number</span>
              ) : null}
            </div>

            <div className="input-box">
              <span htmlFor="collegename" className="details">
                College Name
              </span>
              <input
                id="collegename"
                type="text"
                name="collegename"
                className="form-input"
                placeholder="Enter your college name"
                value={values.userCollegename}
                onChange={handlecollegeChange}
              />
              {submitted && !values.userCollegename ? (
                <span className="alert-message">Please enter a college name</span>
              ) : null}
            </div>

            <div className="input-box">
              <span htmlFor="collegeyear" className="details">
                College Year
              </span>
              <input
                id="collegeyear"
                type="text"
                name="collegeyear"
                className="form-input"
                placeholder="Enter your college year"
                value={values.userCollegeyear}
                onChange={handleyearChange}
              />
              {submitted && !values.userCollegeyear ? (
                <span className="alert-message">Please enter a college year</span>
              ) : null}
            </div>
          </div>
          <button className="form-button" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Forms;
