import React, { useState } from 'react';
import logo from '../images/logo.png';
import { Link, Router, Switch, Route} from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import Projects from './pages/Projects'
// const options = ["Teams", "Projects", "Blogs", "Youtube Partner"];

import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "../theme/GlobalStyles";
import { lightTheme, darkTheme } from "../theme/Themes"

const NavBar = () => {

    const [theme, setTheme] = useState('light');
    const themeTogglerLight = () => {
      setTheme('light')
    }
    const themeTogglerDark = () => {
        setTheme('dark')
    }
    
  
    return(
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <GlobalStyles />
            <Navbar collapseOnSelect expand="lg" style={{fontFamily: 'Raleway', fontWeight: '600'}} >
                <Navbar.Brand>
                    <a href="/">
                        <img
                            src={logo}
                            width='80px'
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo" 
                         />
                    </a>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/events">Events</Nav.Link>
                    <Nav.Link href="/projects">Project Wing</Nav.Link>
                    <Nav.Link href="#leads">Leads</Nav.Link>
                    <NavDropdown title="Know Us" id="collasible-nav-dropdown">
                        <NavDropdown.Item href="/teams">Teams</NavDropdown.Item>
                        <NavDropdown.Item href="/projects">Projects</NavDropdown.Item>
                        <NavDropdown.Item href="http://kiitkaksha.ml/">Blogs</NavDropdown.Item>
                        <NavDropdown.Item href="https://www.youtube.com/channel/UCIQVsJQTeBs_MC685RidK2w">YouTube Partner</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Themes" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={themeTogglerLight}>Light</NavDropdown.Item>
                        <NavDropdown.Item onClick={themeTogglerDark}>Dark</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="#contacts">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </ThemeProvider>
    )
}


export default NavBar
