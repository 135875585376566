import React from "react";
import { data1 } from "../data/data.js";
import styled from "styled-components";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { twitter, faCoffee } from '@fortawesome/fontawesome-free-solid'

export const Teams = () => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        {data1
          ? data1.map((d, i) => (
              <div key={`${d.title}-${i}`}>
                <Headers>{d.title}</Headers>
                <MainContainer key={`${d.value.title}-${i}-container`}>
                  {d.value.map((value, j) => (
                    <Row>
                      <Col>
                    {/* <div> */}
                        {/* <StyledCard style={{ width: "18rem" }}> */}
                        <StyledCard style={{ width: "23rem" }}>
                          <Card.Img variant="top" src={value.img} />
                          <Card.Body>
                            <Button variant="link" target="_blank" href={value.social.twitter}>
                                <i className="fab fa-twitter"></i>
                            </Button> 
                            <Button variant="link" target="_blank" href={value.social.facebook}>
                                <i className="fab fa-facebook"></i>
                            </Button> 
                            <Button variant="link" target="_blank" href={value.social.instagram}>
                                <i className="fab fa-instagram"></i>
                            </Button> 
                            <Button variant="link" target="_blank" href={value.social.linkedin}>
                                <i className="fab fa-linkedin"></i>
                            </Button>                        
                            <Title>{value.name}</Title>
                            <Text>{value.position}</Text>
                          </Card.Body>
                        </StyledCard>
                    {/* </div> */}
                      </Col>
                    </Row>
                  ))}
                </MainContainer>
              </div>
            ))
          : "loading"}
      </div>
    </div>
  );
};

const MainContainer = styled.div`
  position: relative;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
  justify-content: space-evenly;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin: 2rem 2rem;
  border: none;
  align-items: stretch !important;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
`;

const Headers = styled.h2`
  &:before {
    content: "";
    width: 50px;
    height: 2px;
    background: #5d16d9;
    display: inline-block;
    margin: 0 15px 10px 0;
  }

  margin: 3rem 0;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: var(--headingColor);
  font-family: "Raleway", sans-serif;

  &:after {
    content: "";
    width: 50px;
    height: 2px;
    background: #5d16d9;
    display: inline-block;
    margin: 0 0 10px 15px;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px;
  color: #000;
`;

const Text = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
`;

const Social = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  opacity: 0;
  transition: ease-in-out 0.3s;
  text-align: center;
  background: rgba(255, 255, 255, 0.85);
`;

export default Teams;
