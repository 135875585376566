import firebase from 'firebase'


var firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCZ8nShq7CeA8nKPM7UgI_-gNTrLR2ULj8",
    authDomain: "msac-react-form.firebaseapp.com",
    projectId: "msac-react-form",
    storageBucket: "msac-react-form.appspot.com",
    messagingSenderId: "964554693519",
    appId: "1:964554693519:web:79b260d5d786e1a26209fa"
});

var db = firebaseApp.firestore();

export { db };