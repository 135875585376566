export const lightTheme = {
    body: '#FFF',
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#363537',
}
export const darkTheme = {
    body: '#1b1b1b',
    text: '#ffffff',
    toggleBorder: '#6B8096',
    background: '#999',
}
