import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import StoreIcon from '@material-ui/icons/Store';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import EventNoteIcon from '@material-ui/icons/EventNote';
import BarChartIcon from '@material-ui/icons/BarChart';
import AdjustIcon from '@material-ui/icons/Adjust';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CallIcon from '@material-ui/icons/Call';

import hero_1 from '../assets/hero_1.gif';
import client_2 from '../assets/clients/client-2.png';
import counts_img from '../assets/counts-img.svg';
import stats from '../assets/clients/stats.png';
import meter from '../assets/meter.png';
import ball from '../assets/ball.png';
import docs from '../assets/docs.png';
import earth from '../assets/earth.png';
import ayush from '../assets/team/team-1.jpg';
import taneesh from '../assets/team/team-4.jpg';

import '../css/Forms.css';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
    }
  },
  iconContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingBottom: '10px',
    }
  },
  lastRowContainer: {
    paddingLeft: '5em',
    paddingRight: '5em',
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '20px',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1.5em',
      paddingRight: '1.5em',
      alignItems: 'center',
    }
  },
  sendButton: {
    ...theme.typography.estimate,
    borderRadius: 50,
    height: 50,
    width: 170,
    fontSize: '1rem',
    color: "#0000FF",
    backgroundColor: "#FFFFFF",
    border: `1px solid`,
    borderColor: "#0000FF",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#0000FF",
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'left',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  },
  bottomForm: {
    '& > *': {
      margin: theme.spacing(1),
      width: '50ch',
    },
  }
}));

export default function Landing() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction='column'>
      <Grid item className={classes.rowContainer} style={{marginTop: '3em'}}>
        <Grid
          item
          container
          direction={matchesMD ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
        >
          <Grid item container direction='column' lg style={{maxWidth: '40em'}}>
            <Grid item>
              <Typography align={matchesMD ? 'center' : 'left'} variant='h2' gutterBottom>
                Microsoft Student<br />Ambassador's Club<br />KIIT
              </Typography>
            </Grid>
            <Grid item>
            <Typography align={matchesMD ? 'center' : 'left'} variant='body1' paragraph>
              Finding your community is more important than ever as classes and social activities take place virtually.
            </Typography>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Button
                variant='contained'
                className={classes.sendButton}
                align={matchesMD ? 'center' : 'left'}
                gutterBottom
              >
                Learn More
              </Button>
            </Grid>
          </Grid>
          <Grid item lg>
          <img
            src={hero_1}
            alt="mountain through binoculars"
            style={{
              maxWidth: matchesSM ? 600 : '40em',
              marginRight: matchesMD ? 0 : '5em',
              marginBottom: matchesMD ? '5em' : 0,
              animation: 'up-down 2s ease-in-out infinite alternate-reverse both',
            }}
          />
        </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.rowContainer} style={{marginTop: '3em', marginLeft: '0px', backgroundColor: '#E8EEFF', display: 'flex', justifyContent: 'space-between'}}>
      <img
        src={client_2}
        alt="mountain through binoculars"
        style={{
          width:'100px',
        }}
       />
       <img
        src={client_2}
        alt="mountain through binoculars"
        style={{
          width:'100px',
        }}
       />
       <img
        src={client_2}
        alt="mountain through binoculars"
        style={{
          width:'100px',
        }}
       />
       <img
        src={client_2}
        alt="mountain through binoculars"
        style={{
          width:'100px',
        }}
       />
       <img
        src={client_2}
        alt="mountain through binoculars"
        style={{
          width:'100px',
        }}
       />
      </Grid>
      <Grid item>
        <Typography align='center' variant='h2' gutterBottom style={{padding: '30px 0'}}>
          ABOUT US
        </Typography>
      </Grid>
      <Grid item className={classes.rowContainer} style={{marginTop: '3em'}}>
        <Grid
          item
          container
          direction={matchesMD ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
        >
          <Grid item container direction='column' lg style={{maxWidth: '40em', maxHeight: '45vh'}}>
            <Grid item>
              <Typography align={matchesMD ? 'center' : 'left'} variant='body1' paragraph>
                Microsoft Student Ambassadors' Community KIIT is a technical community, under the wing of Microsoft Learn Student Ambassadors, showcasing and nurturing talents under the well known and esteemed name of the organisation, to carry forth and express it's legacy. We conduct events all over campus (and outside campus), inspiring students to pursue knowledge, in their own unique ways. Along with this, we aspire to give the push they need through our club's work, and through training in the various domains that we provide.
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg>
            <Typography align={matchesMD ? 'center' : 'right'} variant='h4'>
              Powered by Microsoft Learn Student Ambassadors
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.rowContainer} style={{marginTop: '3em'}}>
        <Grid
          item
          container
          direction={matchesSM ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
        >
          <Grid item lg>
            <img
            src={counts_img}
            alt="mountain through binoculars"
            style={{
              maxWidth: matchesSM ? 400 : '28em',
              marginRight: matchesMD ? 0 : '5em',
              marginBottom: matchesMD ? '5em' : 0,
            }}
          />
          </Grid>
            <Grid item lg>
            <img
              src={stats}
              alt="mountain through binoculars"
              style={{
                maxWidth: matchesSM ? 300 : '25em',
                marginRight: matchesMD ? 0 : '5em',
                marginBottom: matchesMD ? '5em' : 0,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='center' variant='h2' style={{padding: '30px 0 0 0'}}>
          EVENTS
        </Typography>
        <Typography align='center' variant='body1' gutterBottom style={{padding: '0 0 30px 0', color: 'black'}}>
          High Impacted Activites
        </Typography>
      </Grid>
      <Grid>
        <Grid
          item
          container
          direction={matchesSM ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} variant="outlined" style={{height: '300px', width: '300px', boxShadow: '5px 5px 10px'}}>
              <CardContent>
                <img
                src={ball}
                alt="mountain through binoculars"
                style={{
                  maxWidth: matchesSM ? 600 : '4.5em',
                }}
              />
              </CardContent>
              <CardActions>
                <Button size="small" variant='body1'>Code-In-Turns</Button>
              </CardActions>
              <CardContent>
                <Typography style={{color: 'black', fontSize: '1.1rem'}} paragraph>
                  A Coding Competition in form of<br />Cryptic Hunt.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} variant="outlined" style={{height: '300px', width: '300px', boxShadow: '5px 5px 10px'}}>
              <CardContent>
                <img
                src={docs}
                alt="mountain through binoculars"
                style={{
                  maxWidth: matchesSM ? 600 : '4.5em',
                }}
              />
              </CardContent>
              <CardActions>
                <Button size="small" variant='body1'>HackFest</Button>
              </CardActions>
              <CardContent>
                <Typography style={{color: 'black', fontSize: '1.1rem'}} paragraph>
                  A Quarantine Hackfest of 10<br />hours long based on solving real<br />life problems.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} variant="outlined" style={{height: '300px', width: '300px', boxShadow: '5px 5px 10px'}}>
              <CardContent>
                <img
                src={meter}
                alt="mountain through binoculars"
                style={{
                  maxWidth: matchesSM ? 600 : '4.5em',
                }}
              />
              </CardContent>
              <CardActions>
                <Button size="small" variant='body1'>Momentous</Button>
              </CardActions>
              <CardContent>
                <Typography style={{color: 'black', fontSize: '1.1rem'}} paragraph>
                  A Django Workshop, awaring the<br />deployment and penetration<br />demo.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} variant="outlined" style={{height: '300px', width: '300px', boxShadow: '5px 5px 10px'}}>
              <CardContent>
                <img
                src={earth}
                alt="mountain through binoculars"
                style={{
                  maxWidth: matchesSM ? 600 : '4.5em',
                }}
              />
              </CardContent>
              <CardActions>
                <Button size="small" variant='body1'>Concatenation</Button>
              </CardActions>
              <CardContent>
                <Typography style={{color: 'black', fontSize: '1.1rem'}} paragraph>
                  Learn Machine Learning with<br />python and few Hands on Lab.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='center' variant='h2' style={{padding: '30px 0 0 0'}}>
          PROJECTS
        </Typography>
        <Typography align='center' variant='body1' gutterBottom style={{padding: '0 0 30px 0', color: 'black'}}>
          Real Life projects and Hands on Learning
        </Typography>
      </Grid>
      <Grid>
        <Grid
          item
          container
          direction={matchesMD ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} style={{display: 'flex', width: '200px'}}>
              <StoreIcon style={{fontSize: "40", color: '#FFBB2C'}} />
              <CardContent style={{padding: '10px 8px 0px 8px'}}>
                <Typography style={{color: 'black', fontSize: '1.1rem', fontWeight: '700'}} paragraph>
                  Tango Charlie
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} style={{display: 'flex', width: '200px'}}>
              <LocalOfferIcon style={{fontSize: "35", color: '#6F8FFA'}} />
              <CardContent style={{padding: '10px 8px 0px 8px'}}>
                <Typography style={{color: 'black', fontSize: '1.1rem', fontWeight: '700'}} paragraph>
                  Make Carrer Easy
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} style={{display: 'flex', width: '200px'}}>
              <EventNoteIcon style={{fontSize: "40", color: '#E80368'}} />
              <CardContent style={{padding: '10px 8px 0px 8px'}}>
                <Typography style={{color: 'black', fontSize: '1.1rem', fontWeight: '700'}} paragraph>
                  Vibe
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} style={{display: 'flex', width: '200px'}}>
              <BarChartIcon style={{fontSize: "40", color: '#E361FF'}} />
              <CardContent style={{padding: '10px 8px 0px 8px'}}>
                <Typography style={{color: 'black', fontSize: '1.1rem', fontWeight: '700'}} paragraph>
                  DOK AI
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid style={{paddingBottom: '15px'}}>
            <Card className={classes.root} style={{display: 'flex', width: '200px'}}>
              <AdjustIcon style={{fontSize: "40", color: '#FFA76E'}} />
              <CardContent style={{padding: '10px 8px 0px 8px'}}>
                <Typography style={{color: 'black', fontSize: '1.1rem', fontWeight: '700'}} paragraph>
                  Yureka
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item id='leads'>
        <Typography align='center' variant='h2' style={{padding: '30px 0 0 0'}} gutterBottom>
          MEET THE TEAM
        </Typography>
      </Grid>
      <Grid>
        <Grid
          item
          container
          direction={matchesSM ? 'column' : 'row'}
          alignItems='center'
          className={classes.rowContainer}
          style={{display: 'flex', justifyContent: 'space-evenly'}}
        >
          <Grid style={{paddingBottom: '20px'}}>
            <Card className={classes.root} variant="outlined" style={{height: '450px', width: '350px'}}>
              <CardContent style={{padding: '0px'}}>
                <img
                src={ayush}
                alt="mountain through binoculars"
                style={{
                  height: '22em',
                }}
              />
              </CardContent>
              <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px 0 0 0'}}>
                <Typography style={{color: 'black', fontSize: '1.5rem'}} variant='h2' paragraph>
                  Ayush Padia
                </Typography>
              </CardContent>
              <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0'}}>
                <Typography style={{fontSize: '1rem'}} variant='body1' paragraph>
                  MSAC Lead
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Card className={classes.root} variant="outlined" style={{height: '450px', width: '350px'}}>
            <CardContent style={{padding: '0px'}}>
              <img
              src={taneesh}
              alt="mountain through binoculars"
              style={{
                height: '22em',
              }}
            />
            </CardContent>
            <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '5px 0 0 0'}}>
              <Typography style={{color: 'black', fontSize: '1.5rem'}} variant='h2' paragraph>
                Taneesh
              </Typography>
            </CardContent>
            <CardContent style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0'}}>
              <Typography style={{fontSize: '1rem'}} variant='body1' paragraph>
                Vice Lead
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='center' variant='h6' style={{padding: '30px 0 0 0'}} gutterBottom>
          More Team
        </Typography>
      </Grid>
      <Grid item id='contacts'>
        <Typography align='center' variant='h2' style={{padding: '30px 0 0 0'}} gutterBottom> 
          CONTACT US
        </Typography>
      </Grid>
      <Grid style={{alignItems: 'center', justifyContent: 'center'}}>
        <Grid
          item
          container
          direction={matchesSM ? 'column' : 'row'}
          alignItems='center'
          className={classes.lastRowContainer}          
        >
          <Grid item lg>
            <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1.7rem'}} variant='h4' gutterBottom>
              Microsoft Student<br />Ambassador Club
            </Typography>
            <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1rem'}} variant='body1' paragraph>
              Finding your community is more important than ever as<br />classes and social activities take place virtually.
            </Typography>
            <Grid item lg style={{display: 'flex'}} className={classes.iconContainer}>
              <TwitterIcon style={{fontSize: "40", color: '#1DA1F2'}} />
              <InstagramIcon style={{fontSize: "40", color: '#DB2F67'}} />
              <LinkedInIcon style={{fontSize: "40", color: '#0077B5'}} />
            </Grid>
          </Grid>
          <Grid item lg style={{alignItems: 'center', justifyContent: 'center'}}>
            <Grid item style={{display: 'flex'}}>
              <LocationOnIcon style={{fontSize: "40", color: '#1500FF', padding: '7px'}} />
              <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1rem', color: 'black', fontWeight: '700', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                KIIT Road Bhubaneshwar,<br />Odisha 751024, IN
              </Typography>
            </Grid>
            <Grid item style={{display: 'flex'}}>
              <MailOutlineIcon style={{fontSize: "40", color: '#1500FF', padding: '7px'}} />
              <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1rem', color: 'black', fontWeight: '700', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                kiit.mspc@outlook.com
              </Typography>
            </Grid>
            <Grid item style={{display: 'flex'}}>
              <CallIcon style={{fontSize: "40", color: '#1500FF', padding: '7px'}} />
              <Typography align={matchesMD ? 'center' : 'left'} style={{fontSize: '1rem', color: 'black', fontWeight: '700', margin: '0px', display: 'flex', alignItems: 'center'}} variant='body1' paragraph>
                +91 8417090375
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg className={classes.iconContainer}>
            <form className={classes.bottomForm} noValidate autoComplete="off" gutterBottom>
              <TextField id="outlined-basic" label="Name" variant="outlined" style={{width: '100%'}} />
              <TextField id="outlined-basic" label="Email" variant="outlined" style={{width: '100%'}} />
              <TextField id="outlined-basic" label="Subject" variant="outlined" style={{width: '100%'}} />
              <TextField id="outlined-basic" label="Messege" variant="outlined" style={{width: '100%'}} multiline rows={5} />
            </form>
            <Grid item className={classes.buttonContainer}>
                <Button
                  variant='contained'
                  className={classes.sendButton}
                  align={matchesMD ? 'center' : 'left'}
                  gutterBottom
                >
                  SUBMIT
                </Button>
              </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}