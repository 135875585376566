import React from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';

const Footer = () => {
    return (
        <div>
            <FooterDiv id="footer">
                <Container className="container">
                    <Row>
                        <Col lg={6} className="col-lg-6 text-lg-left text-center">
                            <div className="copyright">
                                © Copyright <strong>MSAC KIIT</strong>. All Rights Reserved
                            </div>
                        </Col>
                        <Col lg={6} className="col-lg-6 text-lg-right text-center">
                            <div className="copyright">
                            Developed with ❤ by MSAC Web Team.
                        </div>
                        </Col>
                        <Col lg={6}>
                        <nav className="footer-links text-lg-right text-center pt-4 pt-lg-0">
                        </nav>
                        </Col>
                    </Row>
                </Container>
            </FooterDiv>
        </div>
    )
}

const FooterDiv = styled.footer`
    background: white;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 0;
    color: black;
    font-size: 14px;
    font-weight: 900;
`

export default Footer
