export const data1 = [
  {
    "title": "Club Leads",
    "value": [
      {
        "img": require("../../assets/team/team-1.jpg").default,
        "name": "Ayush Padia",
        "position": "MSAC Lead",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-4.jpg").default,
        "name": "Taneesh",
        "position": "Vice Lead",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
  {
    "title": "Microsoft Student Ambassadors",
    "value" : [
      {
        "img": require("../../assets/team/team-0.jpg").default,
        "name": "Animesh",
        "position": "MLSA BETA",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-5.jpg").default,
        "name": "Abhinav Mukherjee",
        "position": "MLSA BETA",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
  {
    "title": "Content Writers",
    "value" : [
      {
        "img": require("../../assets/team/team-5.jpg").default,
        "name": "Abhinav Mukherjee",
        "position": "MLSA BETA",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-14.jpg").default,
        "name": "Deepshika Sahu",
        "position": "Content Writer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Mayank.jpg").default,
        "name": "Mayank Jain",
        "position": "Content Writer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Rishit.jpg").default,
        "name": "Rishit Divyam",
        "position": "Content Writer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Saniya.jpg").default,
        "name": "Saniya Bhargav",
        "position": "Content Writer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
  {
    "title": "Operations",
    "value" : [
      {
        "img": require("../../assets/team/Rohit.jpg").default,
        "name": "Rohit Jaiswal",
        "position": "Developers POC",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Gaurav.PNG").default,
        "name": "Gaurav Kumar Verma",
        "position": "Tech POC",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Swastika.jpg").default,
        "name": "Swastika Bishnoi",
        "position": "Content POC",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
    ]
  },
  {
    "title": "Designing Team",
    "value" : [
      {
        "img": require("../../assets/team/team-13.jpg").default,
        "name": "Farzia Farhat Islam",
        "position": "Graphic Designer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Anvit.jpg").default,
        "name": "Anvit Dubey",
        "position": "Graphic Designer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Nimisha.jpg").default,
        "name": "Nimisha Das",
        "position": "UI/UX Designer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Shreya.jpg").default,
        "name": "Shreya Agarwal",
        "position": "Graphic Designer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/rahul.jpeg").default,
        "name": "Rahul Mishra",
        "position": "Graphic Designer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
  {
    "title": "Marketing Team",
    "value" : [
      {
        "img": require("../../assets/team/Priyanka.jpg").default,
        "name": "Priyanka Dutta",
        "position": "",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Urja.jpg").default,
        "name": "Urja Jain",
        "position": "",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Sagar.jpg").default,
        "name": "Sagar Satapathy",
        "position": "",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Amrutanshu.jpg").default,
        "name": "Amrutanshu Dash",
        "position": "",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Avinav.jpg").default,
        "name": "Avinav Roy",
        "position": "",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
  {
    "title": "Tech Team",
    "value" : [
      {
        "img": require("../../assets/team/team-22.jpg").default,
        "name": "Anishka Shukla",
        "position": "CORE MEMBER (CyberSecurity)",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-3.jpg").default,
        "name": "Abhinav Srivastava",
        "position": "CORE MEMBER (Web Developer)",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-6.jpg").default,
        "name": "Srijita Mallick",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Medhavi.jpg").default,
        "name": "Medhavi Basera",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Subhayan.jpg").default,
        "name": "Subhayan Das",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Adarsh.jpg").default,
        "name": "Adarsh Pandey",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Akshita.jpg").default,
        "name": "Akshita Sah",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Akash.jpg").default,
        "name": "Akash Chouhan",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/ShreyaDhir.jpg").default,
        "name": "Shreya Dhir",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Arnab1.jpg").default,
        "name": "Arnab Chatterjee",
        "position": "Web Developer",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Arnab2.jpg").default,
        "name": "Arnab Dan",
        "position": "Cloud Computing",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Krishanu.jpg").default,
        "name": "Krishanu Tiwari",
        "position": "CyberSecurity",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/sagnik.png").default,
        "name": "Sagnik Ghosh",
        "position": "Machine Learning",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/Ansh.jpg").default,
        "name": "Ansh Kumar",
        "position": "Machine Learning",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/saatwick.jpg").default,
        "name": "Saatwik Rishi",
        "position": "App Development",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/ayshi.jpeg").default,
        "name": "Ayshi Bhowmik",
        "position": "Cloud Computing",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
    ]
  },
  {
    "title": "Our Alumini",
    "value" : [
      {
        "img": require("../../assets/team/team-10.jpg").default,
        "name": "Anshudip",
        "position": "Founder",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-17.jpg").default,
        "name": "OUM KALE",
        "position": "CORE MEMBER",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-11.jpg").default,
        "name": "ANTRA SHREY",
        "position": "CORE MEMBER",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-19.jpg").default,
        "name": "BHAVESH JAISWAL",
        "position": "CORE MEMBER",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-12.jpg").default,
        "name": "SAYANJIT ROY",
        "position": "CORE MEMBER",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      },
      {
        "img": require("../../assets/team/team-21.jpg").default,
        "name": "SHIVAM SHARMA",
        "position": "CORE MEMBER",
        "social": {
          "twitter": "https://twitter.com",
          "facebook": "https://facebook.com",
          "instagram": "https://instagram.com",
          "linkedin": "https://linkedin.com"
        }
      }
    ]
  },
]