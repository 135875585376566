import React from 'react';
import styled from 'styled-components';
import { Typography, Breadcrumbs, Container, Grid, Link } from '@material-ui/core'
import { Carousel, Item } from 'react-bootstrap'

import tango1 from '../../assets/portfolio/tango-details-1.jpg'
import tango2 from '../../assets/portfolio/tango-details-2.jpg'
import tango3 from '../../assets/portfolio/tango-details-3.jpg'

import mce1 from '../../assets/portfolio/mce-details-1.jpg'
import mce2 from '../../assets/portfolio/mce-details-2.jpg'
import mce3 from '../../assets/portfolio/mce-details-3.jpg'

import vibe1 from '../../assets/portfolio/vibe-detail-1.png'
import vibe2 from '../../assets/portfolio/vibe-detail-2.png'
import vibe3 from '../../assets/portfolio/vibe-detail-3.png'

const Projects = ()  =>{
    return (
        <div>        
            <Header>Project Details</Header>
            <Grid container style={{margin: '20px auto', maxWidth: '90%'}}>
                <Grid item sm={8}>
                <SubHeader>PROJECT-1</SubHeader>
                    <Carousel touch={true} style={{margin: '20px 0'}}>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={tango1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={tango2}
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={tango3}
                            alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Grid>

                <Grid item sm={4}>
                    <SubHeader style={{margin: '100px 20px 0'}}>Tango Charlie</SubHeader>
                    <Muted><strong>Category: </strong>Web Development</Muted>
                    <Muted><strong>Project date: </strong> 31 December, 2020</Muted>
                    <Para>The purpose of the Tango web browser is to provide people with a very safe and fast web browsing experience. The interface is made very interactive. We have introduced Charlie as a token which the user can earn while using the browser. </Para>
                </Grid>
            </Grid>

            <Grid container style={{margin: '20px auto', maxWidth: '90%'}}>

                <Grid item sm={4}>
                    <SubHeader style={{margin: '100px 20px 0'}}>MAKE CARRER EASY</SubHeader>
                    <Muted><strong>Category: </strong> Web Development</Muted>
                    <Muted><strong>Project date: </strong> 5th January, 2021</Muted>                    
                    <Para>Based on Validated Theories Career Chela psychometric assessments are a product of hard research and strong alignment to renown psychometric theories and get hired at right place. </Para>
                </Grid>


                <Grid item sm={8}>
                <SubHeader>PROJECT-2</SubHeader>
                    <Carousel touch={true} style={{margin: '20px 0'}}>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={mce1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={mce2}
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={mce3}
                            alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Grid>
            </Grid>

            <Grid container style={{margin: '20px auto', maxWidth: '90%'}}>
                <Grid item sm={8}>
                <SubHeader>PROJECT-3</SubHeader>
                    <Carousel touch={true} style={{margin: '20px 0'}}>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={vibe1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={vibe2}
                            alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item interval={2000}>
                            <img
                            className="d-block w-100"
                            src={vibe3}
                            alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </Grid>

                <Grid item sm={4}>
                    <SubHeader style={{margin: '100px 20px 0'}}>VIBE</SubHeader>
                    <Muted><strong>Category: </strong> APP</Muted>
                    <Muted><strong>Project date: </strong> In Development</Muted>
                    <Para>The project is given the name Vibe, an online dating platform that intends to connect people and hearts based on their music taste. The application fetches the songs, artists and genres of a user from their respective Spotify accounts and matches the two user based on the similarity of the data. Users can chat with the matched user within the app. The app is build upon Flutter(Dart used for the UI) and Javascript (used as the backend). All the data fetched is stored in a mySQL database. </Para>
                </Grid>
            </Grid>
        </div>
    )
}

const Header = styled.h2`
    font-size: 28px;
    font-weight: light;
    font-family: "Raleway", sans-serif;
    float: left;
    display: flex;
    align-items: start;
    margin: 10px 40px;
    margin-left: 100px;

`

const SubHeader = styled.h2`
    font-size: 28px;
    font-weight: 600;
    margin: 2rem;
    font-family: "Raleway", sans-serif;

`

const BreadLinks = styled(Breadcrumbs)`
    margin-top: 0;
    float: right;

    @media (max-width: 700px) {
        overflow: hidden;
        display: grid;

    }
`

const Muted = styled.p`
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    margin-left: 20px;
    margin-top: 15px;
    color: #505050;

`

const Para = styled.p`
    font-family: "Raleway", sans-serif;
    margin-left: 20px;

`

export default Projects