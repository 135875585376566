import React, { useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NavBar from "./components/NavBar";
import Projects from "./components/pages/Projects";
import Landing from "./components/Landing";
import Forms from "./components/Form/Forms";
import Teams from './components/pages/Teams';
import Footer from './components/Footer';

import theme from "./components/ui/Theme";

import { ThemeProvider } from "@material-ui/styles";

const App = () => {
  return (
    <Container>
      <Router>
        <NavBar />
        <Switch>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/teams">
            <Teams />
          </Route>
          <Route path="/events">
            <Forms />
          </Route>
          <Route path="/">
            <ThemeProvider theme={theme}>
              <Landing />
            </ThemeProvider>
          </Route>
        </Switch>
        <Footer />
      </Router>
    </Container>
  );
};

const Container = styled.div`
  font-family: "Raleway", sans-serif;
`;

export default App;
